// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    appName: 'monitoring-test',
    version: require('../../package.json').version + '-dev',
    outDir: require('../../angular.json').projects.frontend.architect.build.options.deployUrl,
    keycloak: {
      realm: 'monitoring-meg',
      url: 'https://login.syspons.com/auth',
      clientId: 'monitoring-web',
    },
    // parse: {
    //   appId: 'Syspons-Monitoring',
    //   clientKey: 'd6f249a55d4e936c8c2e45469e11bd38',
    //   serverURL: 'http://localhost:1337/api',
    // },
    translatableConfig: {
      languages: ['de', 'en', 'fr'],
      defaultLanguage: 'de',
      optionalLanguages: ['fr'],
    },
    defaultLang: 'de',
    languages: ['de', 'en'],
    logLevel: 600,
    serverLogLevel: 600,
    logoUrl: '/assets/images/giz.png',
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  